.pelajaran_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    font-size: large;
    width: 90%;
    padding: 30px 0;
    margin: 0 auto;
}

.pelajaran_title {
    font-size: 1.5rem;
    text-align: center;
    padding: 30px;
}

@media (max-width: 789px) {
    .pelajaran_title {
        font-size: 1.1rem;
    }

    .pelajaran_container {
        margin: auto 0;
        width: 100%;
        font-size: x-small;
        padding: 30px 5px;
    }
}