.dropdown {
    text-decoration: none;
    color: #050505;
}


.dropdown:hover {
    text-decoration: none;
    color: #000000;
}

.dropdown2 {
    text-decoration: none;
    color: #ffffff;
}

.dropdown2:hover {
    text-decoration: none;
    color: #8b8b8b;
}


.dropdown3 {
    text-decoration: none;
    color: #adadad;
}

.dropdown3:hover {
    text-decoration: none;
    color: #ffffff;
}

.drop_container {
    text-align: center;
}

.nav_social {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0 20px;
}

@media (max-width: 789px) {
    .nav_social {
        padding: 0;
    }

    .nav_social2 {
        margin-left: 15px;
    }
}