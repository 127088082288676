.peringatan_container {
  padding: 0;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  padding: 60px;
}
.peringatan_contain {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 200px;
}

.peringatan_title {
    color: red;
  }

.peringatan_ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.peringatan_ul li{
  width: 600px;
}


.etika {
    width: 50%;
}

@media (max-width: 768px) {
  .peringatan_title {
    font-size: 0.8rem;
  }

  .etika {
    width: 100%;
}
.peringatan_container {
  padding: 0;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.peringatan_ul li{
  width: 280px;
}
}
