.price_cardlist_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    background-color: #9e9e9e65;
}

.price_cardlist_container2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.pricelist_header {
    background-image: url('../../assets/driving.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 380px;
    color: white;
    text-align: center;
}

.pricelist_headeroverlay {
    background-color: rgba(4, 4, 4, 0.488);
    height:100%
}

.pricelist_header h4 {
    padding: 0 90px;
    padding-top: 110px;
    text-shadow: 2px 2px 8px #040404be;
}

.pricelist_header p {
    display: inline-block;
}

.pricelist_goto {
    text-align: center;
    background: #9e9e9e65;
    padding: 30px;
}


@media (max-width: 789px) {

    .pricelist_header{
        height: 85vh;
    }

    .pricelist_header h4{
        font-size: 0.9rem;
        padding: 0px 50px;
        padding-top: 160px;
    }
    .pricelist_header p{
        font-size: 0.6rem;
    }
}