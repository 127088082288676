.pendaftaran_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 60px;
}

@media (max-width: 768px) {
    .pendaftaran_container {
        padding: 30px 0;
        text-align: center;
    }

    .p_img {
        width: 360px;
    }
}