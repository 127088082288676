.jadual_title {
    text-align: center;
    padding: 30px;
}

.jadual_container {
    padding: 30px;
}

.jadual_container2 {
    padding: 30px;
}

@media (max-width: 789px) {
    .jadual_container {
        font-size: x-small;
        padding: 5px;
    }
    .jadual_container2 {
        font-size: x-small;
        padding: 5px;
    }
}
