.form_container {
    width: 80%;
    justify-content: center;
    margin: 0 auto;
    padding: 80px;
}

@media (max-width: 789px) {
    .form_container {
    padding: 30px 0;
    }
}