/* .hubungi_container {
    padding: 60px;
} */

#map {
    height: 80vh;
}

.hubungi_address {
    text-align: center;
    padding: 30px;
}