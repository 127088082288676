.cardlist_container {
	padding: 10px;
	text-align: center;
}

.cardlist_container h4 {
	text-decoration: line-through;
	color: red;
}

.okuBox {
	padding: 30px 10px;
	background-color: rgb(37, 178, 157);
	border-radius: 10px;
	color: white;
}
