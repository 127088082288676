.detail_container {
    background-color: rgb(99, 99, 99);
    display: flex;
    flex-wrap: wrap;
    color: white;
}

.detail_container p {
    padding: 0;
    margin: 0;
    padding: 0.2rem 30px;
    font-size: small;
}

@media(max-width: 768px) {
    .detail_container p {
        font-size: 0.7rem;
        padding: 10px;
    }
}