.header_container {
    height: 100vh;
    width: 100%;
    background-image: url('../../assets/header.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.header_overlay {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.516);
}

.header_content {
    width: 800px;
    padding: 30px;
}

.header_content Button {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .header_content {
        width: auto;
    }
}