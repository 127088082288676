.display_container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center;
    align-items: center; */
    padding: 30px;
    background-color: rgb(36, 36, 36);
    height: 100vh;
}

@media (max-width: 768px) {
    .display_container {
        font-size: xx-small;
        display: flex;
        flex-wrap: wrap;
    }
}